<script lang="ts" setup>
import constants from '~/constants'

async function goToApp (): Promise<void> {
  const userAgent = import.meta.server ? useRequestHeaders()['user-agent'] : navigator.userAgent
  const { appStore, googlePlay } = constants.appUrls

  if (/android/i.test(userAgent || '')) {
    await navigateTo(googlePlay, { external: true })
  }

  if (/iPhone|iPad|iPod/i.test(userAgent || '')) {
    await navigateTo(appStore, { external: true })
  }

  await navigateTo(appStore, { external: true })
}

onMounted(() => {
  setTimeout(async (): Promise<void> => {
    await goToApp()
  }, 3000)
})
</script>

<template>
  <section>
    <div class="flex min-h-[50vh] flex-col items-center justify-center">
      <b class="mb-3 text-lg font-medium md:text-[40px]">Пожалуйста, подождите…</b>

      <Preloader />
    </div>
  </section>
</template>
